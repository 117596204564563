import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";

// Library imports
import { motion } from "framer-motion";

import { currentUserVar, useReactiveVar } from "@apollo";

// Misc Imports
import { Theme } from "@app-types";
import { useTranslation } from "@i18n";
import { makeStyles, pageVariants } from "@styles";
import { navigate } from "@utils";

// Component imports
import { Assets } from "@assets";
import { Grid, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 550,
    height: "auto",
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 64,
    [theme.breakpoints.down("xs")]: {
      width: "85%",
      marginTop: 16,
      height: 450,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 32,
    },
  },
  textContainer: { marginLeft: "auto", marginRight: "auto" },
  successRocket: {
    width: 480,
    [theme.breakpoints.down("xs")]: {
      width: 300,
    },
  },
  thanksText: {
    fontSize: 36,
    fontWeight: 600,
    textAlign: "center",
    marginTop: 24,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,
    },
  },
  confirmationText: {
    fontSize: 18,
    textAlign: "center",
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
}));

export default function SignUpSuccess({ location }) {
  const classes: any = useStyles({});
  const { t } = useTranslation("signUp");

  const user = useReactiveVar(currentUserVar);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.referrer !== "sign-up-completed" || !!user) {
      navigate("/", { replace: true });
      return null;
    }
  }, []);

  const [resent, setResent] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const signedUpEmail: string | undefined = location?.state
    ? location.state.email
    : undefined;

  const handleResend = async (): Promise<void> => {
    setDisabled(true);
    try {
      await Auth.resendSignUp(signedUpEmail);
      setResent(true);
    } catch (err) {
      console.error(err);
    }
    setTimeout(() => setDisabled(false), 2000);
  };

  return location?.state?.referrer !== "sign-up-completed" || !!user ? null : (
    <motion.div initial="out" animate="in" exit="out" variants={pageVariants}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.root}
      >
        <Grid container className={classes.successRocket}>
          <img src={Assets.SUCCESS_ROCKET} alt="signup-success-rocket" />
        </Grid>
        <Grid container className={classes.textContainer}>
          <Typography className={classes.thanksText}>
            {t("success.thanks")}
          </Typography>
          <Typography className={classes.confirmationText}>
            {!resent
              ? `${t("success.confirmation.one")} ${signedUpEmail}.`
              : `${t("success.confirmation.two")} ${signedUpEmail}.`}
          </Typography>
          <Typography className={classes.confirmationText}>
            {!resent
              ? t("success.confirmation.three")
              : t("success.confirmation.five")}
            <br />
            {!resent
              ? t("success.confirmation.four")
              : t("success.confirmation.six")}
          </Typography>
          <Typography className={classes.confirmationText}>
            {t("success.didntReceive")}{" "}
            <strong
              onClick={() => !disabled && handleResend()}
              style={{ cursor: "pointer" }}
            >
              {t("success.sendAgain")}
            </strong>
          </Typography>
        </Grid>
      </Grid>
    </motion.div>
  );
}
